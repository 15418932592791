import React from "react"
import { Router } from "@reach/router"
import Layout from "../../../components/GamesLayout"
import Default from "../../../app/components/Default"
import Profile from "../../../app/components/Profile"
import Event from "../../../app/components/Event"
import EventLocation from "../../../app/components/Event"
import Events from "../../../app/components/Events"
import Collection from "../../../app/components/Collection"
// import AdminRoute from "../../components/Routing"
// import AdminMenu from "../../components/AdminMenu"
// import AdminUsers from "../../components/AdminUsers"
// import AdminUser from "../../components/AdminUser"

const App = () => {
  return (
    <Layout displayContact={false} displayDataFiscal={false} withSearch={false} useIndex={false}>
      <Router basepath="/evento">
        <Events path="/">
          <EventLocation path=":location">
            <Event path=":eventId" />
          </EventLocation>
        </Events>
      </Router>
    </Layout>
  )
}
export default App


// http://localhost:8000/evento/olivos-1657238400000-3cKktgmAcDpgx7gmjfql2d/
// http://localhost:8000/evento/olivos-1663286400000-3cKktgmAcDpgx7gmjfql2d/
import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone';

import { FirebaseUserContext } from '../../../components/GamesLayout'
import FlatFeed from '../../../getStream/components/FlatFeed'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    maxWidth: 320,
    alignContent: 'center',
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tabPanel: {
    padding: 0,
  },
  tabRoot: {
    minWidth: 72,
  }
}));

const FeedHeader = props => {
  const { title } = props
  return (
    <Box marginTop={2} marginBottom={1} borderBottom={1} boxShadow={3}>
      <Grid container direction="row" s={1} alignItems="center">
        <Grid>
          <Box m={1}>
            <HistoryIcon color="secondary" fontSize="large" />

          </Box>
        </Grid>
        <Grid>
          <Typography component="span" variant="h5">{title}</Typography>
        </Grid>
      </Grid>

    </Box>
  )
}

export function IconTabs({setValue, value}) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container direction='column' alignItems="center">
      <Grid>
      <Paper square className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="opciones"
            >
              <Tab value="1" className={classes.tabRoot} icon={<EventAvailableTwoToneIcon />} aria-label="eventos" label={(value === "1") ? "Eventos" : null} />
              <Tab value="2" className={classes.tabRoot} icon={<ShoppingCartTwoToneIcon />} aria-label="shop" label={(value === "2") ? "Shop" : null}/>
              <Tab value="3" className={classes.tabRoot} icon={<HistoryIcon color="secondary" fontSize="large" />} aria-label="actividad" label={(value === "3") ? "Actividad" : null} />
            </Tabs>
          </Paper>

      </Grid>
      </Grid>
  );
}

export function GlobalActivityFeed (props) {
  return (
    <Grid container direction='column' alignItems="center">
    <Grid>
      <FlatFeed feedGroup="notification" includeActor={true} />
    </Grid>
  </Grid>

  )
}

export function ShopActivityFeed (props) {
  return (
    <Grid container direction='column' alignItems="center">
    <Grid>
      <FlatFeed feedGroup="shop" ignoreEnv={true} />
    </Grid>
  </Grid>

  )
}
export function EventActivityFeed (props) {
  return (
    <Grid container direction='column' alignItems="center">
    <Grid>
      <FlatFeed feedGroup="events" ignoreEnv={true} />
    </Grid>
  </Grid>

  )
}


const Default = (props) => {
  const userContext = useContext(FirebaseUserContext)
  const [value, setValue] = React.useState("1");

  const classes = useStyles()
  return (
    <>
    {userContext && <>
      <TabContext value={value}>
        <IconTabs setValue={setValue} value={value} />
        <TabPanel value="1" classes={{root: classes.tabPanel}}>
          <Box pt={0}>
            <EventActivityFeed />
          </Box>

        </TabPanel>
        <TabPanel value="2" classes={{root: classes.tabPanel}}>
          <Box pt={0}>
            <ShopActivityFeed />
          </Box>

        </TabPanel>
        <TabPanel value="3" classes={{root: classes.tabPanel}}>
          <Box pt={0}>
            <GlobalActivityFeed />
          </Box>

        </TabPanel>

      </TabContext>
      
      
    </>}
    {!userContext &&<div>loading...</div>}
    </>
  )
}

export default Default

import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
// import { makeStyles } from '@material-ui/core/styles';

import EventCard from '../../../components/EventCard'

import { EventsContext } from '../../../components/GamesLayout'
// const useStyles = makeStyles(theme => ({
//   root: {
//     maxWidth: 320,
//   },
//   media: {
//     height: 140,
//     paddingTop: '56.25%', // 16:9
//   },
//   large: {
//     width: theme.spacing(10),
//     height: theme.spacing(10),
//   },
//   tabPanel: {
//     padding: 0,
//   },
//   tabRoot: {
//     minWidth: 72,
//   }
// }));


const Events = (props) => {
  const events = useContext(EventsContext)
  console.log('events', events)
  // const classes = useStyles()
  return (
    <>
      <Box width="100%" marginTop={1}>
        <Typography component="h2" variant="h4" align="center">
          Eventos
        </Typography>
        <Grid container direction="row">
        {events && events.list &&
          events.list.map((event, index) => {
            return (
              <Grid item xs={12} lg={4} xl={4} key={event.id} >
                <EventCard data={event} />
              </Grid>
            )
          })
        }

        </Grid>
      </Box>
    </>
  )
}

export default Events
